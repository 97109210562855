*{
  box-sizing: border-box;
}
body{
  color:#222;
}
main{
  padding: 10px;
  max-width: 758px;
  margin: 0 auto;
}
header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  align-items: center;
  margin-top: 30px;
}

header a{
  text-decoration: none;
  color: inherit;
}

header a.logo{
  font-weight: bold;
  font-size: 1.5rem;
}

header nav{
  display: flex;
  gap: 15px;
}
.PostLink{
  text-decoration: none;
  color: inherit;
}
.entries{
  display: flex;
  flex-direction: column; 
}
.delete-btn {
  position: absolute;
  top: 0px; /* Adjust as needed */
  right: 8px; /* Adjust as needed */
  font-size: 10px; /* Size of the "X" */
  cursor: pointer;
  background-color: transparent; /* No background */
  border: none; /* No border */
  padding: 0; /* Remove padding */
}
.dropdown-menu {
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 8px; /* Adjust as needed */
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.dropdown-menu li {
  font-size: 15px;
  cursor: pointer;
}
.dropdown-menu li:hover {
  background-color: #f1f1f1;
}
.entries div.singleEntry2{
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  border-width: 0.1rem;
  margin-bottom: 10px;
  position: relative; /* Make this element the reference for the delete button */
}
div.Post p{
  line-height: 1.7rem;
  font-size: medium;
}

.ql-toolbar {

  position: sticky;
  top: 0;
  z-index: 100; /* 确保工具栏在其他内容上方 */
  background-color: white; /* 背景色与页面一致 */

}
div.Post  img{
  display: block; /* 将图片设为块级元素 */
  margin: 0 auto; /* 使图片水平居中 */
  width: 100%;
  border-radius: 5px;
}

.entries div.singleEntry1{
  display: grid;
    grid-template-columns: 1fr;
  gap: 20px;
  border-width: 0.1rem;
  margin-bottom: 10px;
  position: relative; /* Make this element the reference for the delete button */
}
.entries div.image img{
  width: 100%; /* Keep the aspect ratio intact */
  max-height: 230px;
  border-radius: 4px;
}
@media screen and (min-width: 500px) {
  div.Post  img{
    width: 60%;
  }
  .entries div.singleEntry1 {
    grid-template-columns: 0.9fr 1.1fr;
  }
  .entries div.image img{
    max-height: 230px;
  border-radius: 0px;
  }

}

.entries div.text h2{
  margin: 0;
  font-size: 20px;
}
.entries div.text div.info{
  margin-top: 10px;
  font-size: 13px;
  color: gray;
}
.entries div.text p.summary{
  font-size: 13px;
  line-height: 1.4rem;
}
form.login,form.register{
  max-width: 400px;
  margin: 0 auto;
}
div.Error{
  color: red;
  font-size: small;
}
form.login input,form.register input{
  display: block;
  margin-bottom: 10px;
  width: 100%;
  padding: 5px;
  border: 0;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}
button.submit{
  width: 100%;
  display: block;
  padding: 4px;
  border: 0.2px ;
  background: linear-gradient(45deg, #fff,#222);
  transition: background 1s ease, transform 0.3s ease; 
  border-radius: 5px;
}
button.submit:hover{
  padding: 6px;
  background: linear-gradient(45deg, #222,#fff);
  transform: translateY(-2px);
}
.delete-btn:hover {
  color: darkred; /* Optional: change color on hover */
}
.Post p{
  line-height: 1.4rem;
}
.Post p br{
  margin-top: 2px;
}
.confirm-popup{
  text-align: center;
  margin-bottom: 10px;
}