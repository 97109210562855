.image-upload-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.image-upload-input {
    margin-bottom: 10px;
    display: none;
}
.upload-haha{
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
}
.image-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
}

.image-preview {
    position: relative;
}

.image-preview-img {
    width:100%;
    object-fit: cover;
    border-radius: 8px;
}

.image-remove-button {
    position: absolute;
    top: 0;
    right: 0;
    background: red;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-button {
    width: 100%;
    display: block;
    margin-top: 10px;
    padding: 4px;
    border: 0.2px ;
    background: linear-gradient(45deg, #fff,#222);
    transition: background 1s ease, transform 0.3s ease; 
    border-radius: 5px;
}

.upload-button:hover {
    padding: 6px;
    background: linear-gradient(45deg, #222,#fff);
    transform: translateY(-2px);
}
.custom-textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    resize: vertical; /* Allows the textarea to be resized vertically only */
    box-shadow: inset 0 1px 3px rgba(0,0,0,0.1);
    outline: none; /* Removes the default outline on focus */
    transition: border-color 0.3s ease;
}

.custom-textarea:focus {
    border-color: #007BFF; /* Changes border color on focus */
    box-shadow: 0 0 5px rgba(0,123,255,0.5); /* Adds a shadow on focus */
}